.slick-prev,
.slick-prev::before {
  color: rgb(90, 90, 90);
  left: 25px;
  z-index: 9999;
}

.slick-next,
.slick-next::before {
  color: rgb(90, 90, 90);
  right: 25px;
  z-index: 9999;
}

.slick-dots,
.slick-dots::before {
  color: rgb(90, 90, 90);
  bottom: 25px;
}

code.inline-code {
  background-color: #f8f8ff;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
